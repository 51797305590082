import { colors } from 'fitify-ui/src/theme/colors'
import { css, styled } from 'styled-components'

import { BREAKPOINTS, StyledAsProps } from '../utils/styled'

export const Heading = styled.h1<StyledAsProps>`
  align-self: flex-start;
  font-family: ${(props) => props.theme.fonts.heading};
  font-style: normal;
  line-height: 120%;
  letter-spacing: 0.01em;
  margin: 0;
  padding: 0;

  color: ${colors.Black};
  font-weight: 500;

  ${(props) => {
    switch (true) {
      case props.theme.hcV1:
        return css`
          font-size: 1.25rem;

          & > em {
            font-style: normal;
            background: ${colors.Green400};
            border-radius: 4px;
            padding: 0 3px 3px 3px;
            margin-right: 0;
          }

          b {
            color: ${colors.Black};
          }
        `
      case props.theme.digital:
        return css`
          color: ${colors.White};
          font-size: 2rem;
          font-weight: 700;

          @media ${BREAKPOINTS.MD.lte} {
            font-size: 1.5rem;
          }
        `
      default:
        return null
    }
  }}
`

export const Text = styled.p<StyledAsProps>`
  color: ${(props) => props.color || props.theme.colors.text};
  font-weight: ${(props) => props.$weight || 'normal'};
  text-align: ${(props) => props.$textAlign || 'inherit'};
  text-transform: ${(props) => props.$textTransform || 'inherit'};
  white-space: pre-line;

  margin: 0;
  padding: 0;

  ${(props) => {
    switch (props.$size) {
      case 'xs':
        return css`
          font-family: ${(props) => props.theme.fonts.text};
          font-size: 0.875rem;
          line-height: 120%;
        `
      case 'sm':
        return css`
          font-family: ${(props) => props.theme.fonts.text};
          font-size: 1.125rem;
          line-height: 120%;
        `
      case 'md':
        return css`
          font-family: ${(props) => props.theme.fonts.text};
          font-size: 1.25rem;
          line-height: 120%;
        `
      case 'lg':
        return css`
          font-family: ${(props) => props.theme.fonts.text};
          font-size: 1.5rem;
          line-height: 100%;
        `
      case 'xl':
        return css`
          font-family: ${(props) => props.theme.fonts.heading};
          font-size: 2rem;
          line-height: 120%;
          @media ${BREAKPOINTS.MD.lte} {
            font-size: 24px;
          }
        `
      case 'error-title':
        return css`
          font-family: ${(props) => props.theme.fonts.heading};
          font-size: 5.5rem;
          line-height: 120%;
        `
      default:
        return css`
          font-family: ${(props) => props.theme.fonts.text};
          font-size: 1rem;
          line-height: 120%;
        `
    }
  }}

  b {
    font-weight: 600;
    color: ${(props) => props.theme.colors.text};
  }

  del,
  strike {
    color: ${colors.Blue400};
  }
`

export const UnderlineLink = styled.a`
  text-decoration: underline;
  color: inherit;

  &:hover,
  &:active,
  &:visited {
    color: inherit;
  }
`

export const SubscriptionQuestionHeading = styled.h3<StyledAsProps>`
  font-family: 'Graphik LCG Web';
  color: ${colors.Black};
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  padding: 0;
  text-align: center;
  margin: 0 0 24px 0;
`

export const PitchText = styled(Text)`
  flex: 1;
  ${(props) => {
    switch (true) {
      case props.theme.digital:
        return css`
          text-align: center;
          color: ${colors.Blue75};
          b {
            color: ${(props) => props.theme.colors.vibrantGreen};
          }
        `
      default:
        return css`
          color: ${colors.Gray700};

          b {
            color: ${colors.Black};
          }
        `
    }
  }}
`

export const SubscriptionDescriptionTextBox = styled.div`
  position: relative;
  padding: 1rem;
  background-color: ${colors.White};
  border-radius: 12px;

  @media ${BREAKPOINTS.MD.gte} {
    &::after {
      content: '';
      position: absolute;
      top: -20px;
      right: 15%;
      border-width: 10px;
      border-style: solid;
      border-color: transparent transparent #fff transparent;
    }
  }
`

export const SubscriptionDescriptionText = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: ${({ theme }) => theme.colors.text};
  min-height: 36px;
  b {
    font-weight: 500;
    color: ${({ theme }) => theme.colors.text};
  }
`

export const StyledAuthDescriptionText = styled(Text)`
  ${({ theme }) => {
    if (theme.digital) {
      return css`
        color: ${colors.Blue75};
      `
    }

    return css`
      color: ${colors.Gray700};
    `
  }}
`
