import { colors } from 'fitify-ui/src/theme/colors'
import { createGlobalStyle, css } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  :root {
      *,
      *:before,
      *:after {
        box-sizing: border-box;
        -webkit-tap-highlight-color: transparent;
      }

      img {
        pointer-events: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -o-user-select: none;
        user-select: none;
      }

      input[type='number'] {
        -moz-appearance: textfield;
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }

      ${({ theme }) => {
        if (theme.hcV1) {
          return css`
            body {
              margin: 0;
              padding: 0;
              color: ${colors.Gray700};
              background: ${colors.Gray100};
              font-family: ${(props) => props.theme.fonts.text};
            }
          `
        } else {
          return css`
            body {
              margin: 0;
              padding: 0;
              color: ${colors.Gray700};
              background: ${colors.Blue850};
              font-family: ${(props) => props.theme.fonts.text};
            }
          `
        }
      }}
  }
`
