import { styled } from 'styled-components'

interface ProgressBarProps {
  percentage?: number
}

const ProgressLine = styled.div<{ $percentage?: number }>`
  width: ${(props) => props.$percentage || 0}%;
  height: 100%;
  background: ${(props) => props.theme.colors.progressBarLine};
`

const ProgressBarWrapper = styled.div`
  width: 100%;
  height: 0.125rem;
  background: ${(props) => props.theme.colors.progressBar};
`

export const ProgressBar = ({ percentage }: ProgressBarProps) => {
  return (
    <ProgressBarWrapper>
      <ProgressLine $percentage={percentage} />
    </ProgressBarWrapper>
  )
}
