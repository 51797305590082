import { colors } from 'fitify-ui'
import { styled } from 'styled-components'

import { BREAKPOINTS } from '../../utils'

export const StyledFooter = styled.footer`
  text-align: center;
  padding: 1rem;

  p,
  a {
    color: ${(props) => {
      switch (true) {
        case props.theme.hcV1:
          return colors.Gray600
        case props.theme.digital:
        default:
          return colors.Blue75
      }
    }};
  }

  a {
    transition:
      color 0.3s,
      opacity 0.3s;

    &:hover {
      opacity: 0.75;
    }
  }
`

export const StyledDesktopDisclaimer = styled.span`
  display: block;

  @media ${BREAKPOINTS.MD.lte} {
    display: none;
  }
`
