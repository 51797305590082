import { colors } from 'fitify-ui'
import { css, styled } from 'styled-components'

import { Stack } from '../Stack'

export const StyledImperialWrapper = styled(Stack)`
  label {
    width: calc(50% - 0.5rem);
  }
`

export const FormLabel = styled.label<{ $invalid?: boolean }>`
  border: none;
  padding: 0;
  margin: 0;

  font-size: 30px;
  font-weight: bold;

  width: 100%;
  height: 56px;

  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  flex-wrap: nowrap;
  border-radius: 12px;

  border: solid ${(props) => props.theme.colors.inputBorder};
  border-width: ${(props) => (props.theme.digital ? '2px' : '1px')};
  transition: all 0.2s;

  &:focus-within {
    background: transparent;
    border-color: ${(props) => props.theme.colors.inputBorderFocus};
  }

  & legend {
    padding-right: 18px;
    color: ${colors.Gray500};
    font-size: 14px;

    & + input {
      text-indent: 32px;
    }

    ${({ theme }) => {
      if (theme.digital) {
        return css`
          font-weight: 500;
          color: ${colors.Blue550};
        `
      }

      return css`
        color: ${colors.Gray500};
      `
    }}
  }

  ${(props) => {
    if (props.$invalid) {
      return css`
        &:not(:focus-within) {
          border-color: ${props.theme.colors.inputBorderError};
        }
      `
    }
    return null
  }}
`

export const StyledFormInput = styled.input`
  width: 100%;
  padding: 0;

  background: none;
  border: none;
  font-family: ${(props) => props.theme.fonts.text};
  font-size: 100%;
  font-weight: 500;
  text-align: center;
  outline: none;
  color: ${(props) => props.theme.colors.inputTextColor};

  &::placeholder {
    color: ${(props) => props.theme.colors.inputPlaceholderColor};
  }

  &:is(:-webkit-autofill, :autofill) {
    background-clip: text;
    -webkit-text-fill-color: ${(props) =>
      props.theme.colors.inputTextColor} !important;
  }
`
