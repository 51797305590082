export const themeFonts = {
  digital: {
    heading: 'Graphik LCG Web',
    text: 'Graphik LCG Web',
  },
  wrapped: {
    heading: 'Graphik LCG Web',
    text: 'Graphik LCG Web',
  },
  hcV1: {
    heading: 'Graphik Wide Web',
    text: 'Graphik LCG Web',
  },
}

export const themeSizes = {
  general: {
    contentWidth: 480,
    headerHeight: 82,
    headerHeightMobile: 66,
  },
  digital: {
    planContainerWidth: 375,
  },
}
