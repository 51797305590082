import { colors } from 'fitify-ui/src/theme/colors'
import { styled, css } from 'styled-components'

import { Stack } from '../../Stack'

export const StyledSegmentControl = styled(Stack)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: min-content;
  align-self: center;
  gap: 2px;
  font-weight: bold;
  text-align: center;
  padding: 2px;
  border-radius: 12px;

  ${({ theme }) => {
    if (theme.digital) {
      return css`
        background: ${theme.colors.blueBase};
        color: ${colors.Blue75};
      `
    }

    return css`
      background: ${colors.Gray200};
    `
  }}
`

export const StyledSegmentControlItem = styled.a<{ selected?: boolean }>`
  cursor: pointer;
  white-space: nowrap;
  outline: none;
  transition:
    color 0.2s,
    background-color 0.2s;
  min-width: 60px;
  padding: 9px 5px;
  border-radius: 10px;
  font-size: 13px;
  font-style: normal;
  backdrop-filter: blur(4px);

  @media (hover: hover) {
    &:hover {
      ${({ selected }) =>
        selected &&
        css`
          cursor: auto;
        `}
    }
  }

  ${({ selected }) => css`
    color: ${selected ? colors.Blue400 : colors.Gray600};
    background: ${selected ? colors.White : 'none'};
    border: ${selected ? `1px solid ${colors.Gray300}` : 'none'};

    &:active {
      color: ${selected ? colors.Blue400 : colors.Gray900};
      background: ${selected ? 'none' : colors.Gray300};
    }

    @media (hover: hover) {
      &:hover {
        color: ${selected ? colors.Blue400 : colors.Gray900};
        background: ${selected ? colors.White : colors.Gray300};
      }
    }
  `}

  ${(props) =>
    props.theme.digital &&
    css`
      font-weight: 500;
      color: ${props.selected ? colors.Blue950 : colors.Blue75};
      background: ${props.selected ? props.theme.colors.vibrantGreen : 'none'};
      border: none;

      &:active {
        color: ${props.selected ? colors.Blue950 : colors.Blue75};
        background: ${props.selected
          ? 'none'
          : props.theme.colors.vibrantGreen};
      }

      @media (hover: hover) {
        &:hover {
          color: ${props.selected ? colors.Blue950 : colors.White};
          background: ${props.selected
            ? props.theme.colors.vibrantGreen
            : colors.Blue850};
        }
      }
    `}
`
