import { colors } from 'fitify-ui/src/theme/colors'
import { styled, css } from 'styled-components'

import { zIndexes } from '../../theme/zIndexes'
import { BREAKPOINTS } from '../../utils/styled'
import { StyledCircleButton } from '../Button'
import { Stack } from '../Stack'

export const StyledHeader = styled.header`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: ${zIndexes.header};
`

export const StyledHeaderContainer = styled.header`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${({ theme }) => theme.sizes.headerHeight}px;
  padding: 0 3rem;
  background: ${({ theme }) => theme.colors.header};

  @media ${BREAKPOINTS.MD.lte} {
    height: ${({ theme }) => theme.sizes.headerHeightMobile}px;
    padding: 0 1rem;
  }
`

export const StyledPromoRedirect = styled(StyledCircleButton)`
  position: absolute;
  left: 15px;
`

export const StyledLogoContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: ${({ theme }) => theme.sizes.contentWidth}px;
  margin: 0 auto;
`

export const StyledLogo = styled.img<{
  $responsive?: { width: number; height: number }
}>`
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

  ${({ $responsive }) => {
    if ($responsive?.width && $responsive?.height) {
      return css`
        @media ${BREAKPOINTS.MD.lte} {
          width: ${$responsive.width}px;
          height: ${$responsive.height}px;
        }
      `
    }

    return null
  }}
`

/* USER MENU */
export const StyledUserContainer = styled.div`
  position: absolute;
  max-width: 20%;
  right: 40px;
  display: flex;
  align-items: center;
  gap: 8px;

  @media ${BREAKPOINTS.MD.lte} {
    right: 16px;
  }
`

export const StyledUserAvatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 100%;
  object-fit: cover;
  object-position: center;

  @media ${BREAKPOINTS.MD.lte} {
    display: none;
  }
`

export const StyledUserData = styled(Stack)`
  flex-direction: column;
`

export const StyledUserName = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.textSecondary};
`

export const StyledUserEmail = styled(StyledUserName)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const StyledLogoutLink = styled.a`
  align-self: flex-end;
  font-style: normal;
  font-weight: ${(props) => (props.theme.digital ? 400 : 500)};
  font-size: 14px;
  color: ${(props) => (props.theme.digital ? colors.Blue75 : colors.Gray500)};
  text-decoration: none;

  cursor: pointer;
`
