import { addAlpha, colors as fitifyUiColors } from 'fitify-ui/src/theme/colors' // Import colors

const blueBase = '#083168'
const vibrantGreen = '#AFFF2D'

export const themeColors = {
  general: {
    blueBorder: '#134A90',
    blueBase,
    vibrantGreen,
  },
  wrapped: {
    header: addAlpha(fitifyUiColors.Gray300, 0.16),
    error: fitifyUiColors.Red400,
    text: fitifyUiColors.White,
    textSecondary: fitifyUiColors.White,
    textDisabled: fitifyUiColors.Gray400,
    primary: fitifyUiColors.Black,
    primaryText: fitifyUiColors.White,
    primaryHover: fitifyUiColors.Black,
    primaryFocus: fitifyUiColors.Gray800,
    primaryDisabled: fitifyUiColors.Gray300,
    primaryColorDisabled: fitifyUiColors.Gray400,
    secondary: fitifyUiColors.White,
    secondaryText: fitifyUiColors.Black,
    secondaryHover: fitifyUiColors.White,
    secondaryFocus: fitifyUiColors.White,
    secondaryActive: fitifyUiColors.White,
    secondaryDisabled: fitifyUiColors.Gray300,
    secondaryColorDisabled: fitifyUiColors.Gray400,
    progressBar: addAlpha(fitifyUiColors.White, 0.5),
    progressBarLine: vibrantGreen,
    inputBorder: fitifyUiColors.Blue550,
    inputBorderFocus: fitifyUiColors.Blue75,
    inputBorderError: fitifyUiColors.Red500,
    inputTextColor: fitifyUiColors.White,
    inputPlaceholderColor: fitifyUiColors.Blue550,
    animatedInputColor: fitifyUiColors.White,
    animatedInputLabelColor: fitifyUiColors.Blue550,
    animatedInputBorder: fitifyUiColors.Blue550,
    animatedInputBorderFocus: fitifyUiColors.White,
    animatedInputAutofill: fitifyUiColors.White,
  },
  digital: {
    header: blueBase,
    error: fitifyUiColors.Red400,
    text: fitifyUiColors.White,
    textSecondary: fitifyUiColors.White,
    textDisabled: fitifyUiColors.Gray400,
    primary: vibrantGreen,
    primaryText: fitifyUiColors.Blue950,
    primaryHover: fitifyUiColors.Green200,
    primaryFocus: fitifyUiColors.Green200,
    primaryDisabled: addAlpha(vibrantGreen, 0.3),
    primaryColorDisabled: blueBase,
    secondary: fitifyUiColors.White,
    secondaryText: fitifyUiColors.Black,
    secondaryHover: fitifyUiColors.Gray300,
    secondaryFocus: fitifyUiColors.Gray300,
    secondaryActive: fitifyUiColors.Gray400,
    secondaryDisabled: fitifyUiColors.Gray300,
    secondaryColorDisabled: fitifyUiColors.Gray400,
    promo: fitifyUiColors.Yellow300,
    promoText: fitifyUiColors.Black,
    promoHover: fitifyUiColors.Yellow200,
    promoFocus: fitifyUiColors.Yellow200,
    promoActive: fitifyUiColors.Yellow200,
    promoDisabled: `linear-gradient(180deg, #FBFF3E 0%, ${fitifyUiColors.Yellow450} 100%)`,
    promoColorDisabled: `linear-gradient(180deg, #FBFF3E 0%, ${fitifyUiColors.Yellow450} 100%)`, // TODO update according to Figma conversation
    inputBorder: fitifyUiColors.Blue550,
    inputBorderFocus: fitifyUiColors.Blue75,
    inputBorderError: fitifyUiColors.Red500,
    inputTextColor: fitifyUiColors.White,
    inputPlaceholderColor: fitifyUiColors.Blue550,
    animatedInputColor: fitifyUiColors.White,
    animatedInputLabelColor: fitifyUiColors.Blue550,
    animatedInputBorder: fitifyUiColors.Blue550,
    animatedInputBorderFocus: fitifyUiColors.White,
    animatedInputAutofill: fitifyUiColors.White,
    progressBar: '#041e42',
    progressBarLine: vibrantGreen,
    faqBackground: fitifyUiColors.Blue850,
    faqIcon: fitifyUiColors.White,
    faqIconBackground: fitifyUiColors.Blue550,
  },
  hcV1: {
    header: fitifyUiColors.White,
    error: fitifyUiColors.Red400,
    text: fitifyUiColors.Black,
    textSecondary: fitifyUiColors.Gray700,
    primary: fitifyUiColors.Green400,
    primaryText: fitifyUiColors.Black,
    primaryHover: fitifyUiColors.Green200,
    primaryFocus: fitifyUiColors.Green200,
    primaryActive: fitifyUiColors.Green100,
    primaryDisabled: fitifyUiColors.Gray300,
    primaryColorDisabled: fitifyUiColors.White,
    secondary: fitifyUiColors.Black,
    secondaryText: fitifyUiColors.White,
    secondaryHover: fitifyUiColors.Gray800,
    secondaryFocus: fitifyUiColors.Gray800,
    secondaryActive: fitifyUiColors.Gray600,
    secondaryDisabled: fitifyUiColors.Gray300,
    secondaryColorDisabled: fitifyUiColors.Gray400,
    inputBorder: fitifyUiColors.Gray400,
    inputBorderFocus: fitifyUiColors.Gray900,
    inputBorderError: fitifyUiColors.Red500,
    inputTextColor: fitifyUiColors.Black,
    inputPlaceholderColor: fitifyUiColors.Gray600,
    animatedInputColor: fitifyUiColors.Black,
    animatedInputLabelColor: fitifyUiColors.Gray600,
    animatedInputBorder: fitifyUiColors.Gray500,
    animatedInputBorderFocus: fitifyUiColors.Gray900,
    animatedInputAutofill: fitifyUiColors.Black,
    progressBar: fitifyUiColors.Gray300,
    progressBarLine: fitifyUiColors.Blue400,
  },
}
